<template>
    <div>
        <landing-header></landing-header>
        <main class="static-page">
            <div class="m-3 p-3 text-center">
                <h4>ABOUT FTD LOGISTICS</h4>
            </div>
            <section class="p-5">
                <p>
                    FTD Logistics is a fast growing delivery company in Lagos, Nigeria that provides safe and reliable delivery
services to clients home and abroad. The company was founded in October 2015, and has recorded
tremendous growth over the years some of which are subsidiary branches across Nigeria.

                </p>
                <p>
                    The application of our core skills and problem solving efficiency in the business environment has been
able to bring about stability in all ramifications. As a result of this working system, our customers' needs
are satisfied and we are sought after.

                </p>
                <p>
                    At FTD Logistics our unwavering commitment is to provide our customers with the best quality delivery
solutions. We relentlessly pursue providing the most responsive, customer oriented, point-to-point
delivery service worldwide.

                </p>
                <p>
                    We are committed to remaining at the forefront of the latest same-day delivery and courier technology.
It is our goal to provide such superior service that our customers rave about our service. Our logistics
services offer a high degree of reliability while remaining cost efficient.

                </p>
                <p>
                    Our motto, “...redefining convenience”, goes to further show our commitment to ensuring we take
logistics to the next level utilizing ingenuity in technology integration.
                </p>
            </section>
        </main>
        <landing-footer></landing-footer>
    </div>
</template>
<script>
import footerVue from '../layout/footer.vue'
import headerVue from '../layout/header.vue'
export default {
    name: "AboutUs",
    components: {landingHeader: headerVue, landingFooter: footerVue}
}
</script>